@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;700;800&display=swap");
body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

.ql-container .ql-snow {
  min-height: 300px !important;
}

/* Primary Color - #7743db
   Secondary Color - #3c096c #ff7900
   Other Colors - #f1eaff
   Grey Shades - #818084 #253542
*/
