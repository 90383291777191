.Navbar {
  width: 100%;
  padding: 1.5rem 10rem;
  background: #f1eaff;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  color: #7743db;

  @media screen and (max-width: 1000px) {
    padding: 1rem 2rem;
  }

  &__logo {
    font-size: 2rem;
    font-weight: 900;
    display: flex;
    gap: 1rem;
    align-items: baseline;
    color: #7743db;
    text-decoration: none;
  }

  &__links {
    color: #818084;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    font-size: 1.2rem;
    @media screen and (max-width: 1000px) {
      gap: 1rem;
    }

    &__item {
      display: flex;
      align-items: baseline;
      gap: 0.5rem;
      cursor: pointer;
      transition: transform 0.3s ease-out;
      &:hover {
        color: #7743db;
        opacity: 0.8;
        transform: translate(-5px, 0px);
      }

      &__active {
        color: #7743db;
        font-weight: 600;
      }
    }

    &__options {
      display: none;
      position: absolute;
      left: 0;
      width: 100%;
      margin: 0 auto;
      padding: 2rem 5rem 5rem;
      align-items: flex-start;
      justify-content: center;
      row-gap: 1rem;
      column-gap: 5rem;
      flex-wrap: wrap;
      background: #fff;
      border-bottom: 1px solid #ddd;
      transition: transform 3s ease-out;
      z-index: 5;

      @media screen and (max-width: 1000px) {
        padding: 2rem;
        gap: 1rem;
        justify-content: flex-start;
      }

      &__active {
        display: flex;
      }

      &__container {
        & h4 {
          font-weight: 500;
          margin: 0 0 1rem;
        }
        & a {
          display: block;
          padding: 0.5rem 0;
          text-decoration: none;
          color: #818084;
          transition: transform 0.3s ease-out;

          &:hover {
            color: #7743db;
            opacity: 0.8;
            transform: translate(-5px, 0px);
          }
        }
      }
    }
  }
  &__ctas {
    font-size: 1.2rem;
    &__login {
      display: flex;
      font-size: 1.1rem;
      gap: 0.5rem;
      align-items: center;
      background: transparent;
      color: #7743db;
      outline: none;
      border: none;
      font-weight: 600;
      cursor: pointer;
      transition: transform 0.3s ease-out;
      text-decoration: none;

      &:hover {
        opacity: 0.8;
        transform: translate(-5px, 0px);
      }
    }
  }
}
