$primary-color: #f1eaff;
$text-color: #7743db;
$mobile-breakpoint: 768px;

.activeLink {
  color: $text-color !important;
}

.navbar {
  background: $primary-color;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &__logo {
    font-size: 2rem;
    font-weight: 900;
    display: flex;
    gap: 1rem;
    align-items: baseline;
    color: #7743db;
    text-decoration: none;
  }

  .menuBtn {
    display: none;
    font-size: 1.8rem;
    color: $text-color;
    background: none;
    border: none;
    cursor: pointer;
  }

  .navList {
    display: flex;
    list-style: none;
    gap: 1rem;

    .navItem {
      position: relative;
    }

    .navLink {
      color: #818084;
      text-decoration: none;
      font-size: 1.1rem;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      transition: background 0.3s ease;
      font-weight: 500;
      gap: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: rgba(119, 67, 219, 0.8);
      }
    }

    .dropdown {
      position: relative;
      cursor: pointer;
      margin: 0;

      &:hover .dropdownMenu {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
      }

      .dropdownMenu {
        position: absolute;
        top: 100%;
        right: 0;
        background: white;
        border-radius: 5px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        list-style: none;
        padding: 0;
        min-width: 200px;
        opacity: 0;
        transform: translateY(10px);
        transition: all 0.3s ease;
        pointer-events: none;
        z-index: 1000;
        font-size: 1rem;

        .dropdownLink {
          display: block;
          padding: 0.7rem 1rem;
          color: $text-color;
          text-decoration: none;
          transition: background 0.3s ease;

          &:hover {
            background: #eee;
          }
        }
      }
    }
  }

  // Mobile Menu Styles
  @media (max-width: $mobile-breakpoint) {
    .menuBtn {
      display: block;
    }

    .navList {
      position: absolute;
      top: 79%;
      left: 0;
      width: 100%;
      background: $primary-color;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0;
      transform: translateY(-100%);
      transition: transform 0.3s ease;
      display: none;
      z-index: 999;
    }

    .open {
      transform: translateY(0);
      display: flex;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }

    .navItem {
      width: 100%;
      text-align: center;
    }

    .dropdownMenu {
      position: static;
      background: none;
      box-shadow: none;
      opacity: 1;
      transform: none;
      pointer-events: auto;
      width: 100%;
      right: 0;
    }

    .dropdownLink {
      color: white;
      background: none;
      text-align: center;
      padding: 0.5rem 0;
    }
  }
}

.logout {
  display: flex;
  font-size: 1.1rem;
  gap: 0.5rem;
  align-items: center;
  background: transparent;
  color: #7743db;
  outline: none;
  border: none;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
    transform: translate(-5px, 0px);
  }
}

.showDropdown {
  opacity: 1 !important;
  transform: translateY(0) !important;
  pointer-events: auto !important;
  display: block !important;
}

@media (max-width: 768px) {
  .dropdownMenu {
    position: static;
    opacity: 0;
    pointer-events: none;
    display: none;
    transform: translateY(-10px);
    transition: all 0.3s ease;
    background: none;
  }
}
